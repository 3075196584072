<template>
  <div>
    <div class="d-flex">
      <h1 class="headline--md text-bold text-uppercase">All users</h1>
      <button class="btn btn--primary btn--md" @click="onExportUsersList">Export as CSV</button>
    </div>

    <div class="box box--sm box--gray radius-bottom-0 mt-16 d-flex">
      <div class="d-flex">
        <selection-box
          v-model="params.verified"
          :options="accountStatusOptions"
          :is-align-right="true"
          :on-check="onGetUsers"
          class="mr-8"
          selected-box-class="is-lg"
        />

        <date-range-picker
          v-model="dateContext"
          opens="left"
          format="yyyy-mm-dd hh:mm"
          @toggle="isDateRangeOpen = !isDateRangeOpen"
          @update="onGetUsers"
          :class="['mr-16 bg-gray', { active: isDateRangeOpen }]"
          :append-to-body="true"
          :date-format="dateFormat"
        />
      </div>

      <div class="d-flex">
        <search-box class="mr-8" v-model="searchText" holder-text="Search by email or wallet address" @on-search="onGetUsers" />

        <selection-box v-model="params.perPage" :options="pagerOptions" :is-align-right="true" :on-check="onGetUsers" />
      </div>
    </div>

    <div v-if="users" class="table-wrapper table-wrapper--fluid table-wrapper--expand radius-top-0">
      <table class="table table--striped">
        <thead>
          <tr>
            <th>Acc. ID</th>
            <th>Email</th>
            <th>Referal Code</th>
            <th>Registered Date</th>
            <th>Account Status</th>
          </tr>
        </thead>

        <tbody>
          <router-link
            v-for="user in users.data"
            :key="user.id"
            :to="{ name: 'User', params: { id: user.id } }"
            class="link--clone"
            tag="tr"
          >
            <td :class="{ 'badge badge--triangle-shape tooltip': user.blacklist > 0 }">
              {{ user.id }}
              <span class="tooltip__text" v-if="user.blacklist > 0">{{ user.blacklist | format_blacklist_type }}</span>
            </td>
            <td class="clipboard-copy">
              <span class="clipboard-copy__text">
                <span class="clipboard-copy__text-inner">{{ user.email }}</span>
                <copy-clipboard :is-external="false" tooltip-class="custom" @on-copy="doCopy($event)" />
              </span>
            </td>
            <td :class="{ 'text-uppercase': user.referralCode }">{{ user.referralCode || 'Not Available' }}</td>
            <td v-html="`${getMomentLTSFormatted(user.createdAt)} UTC`" />
            <td
              class="text-uppercase text-bold"
              :class="user.documentVerification === 2 ? 'text-success' : 'text-error'"
            >
              {{ user.documentVerification | format_verified_text }}
            </td>
          </router-link>
        </tbody>
      </table>

      <div class="text-center mt-32">
        <p>{{ users.total | format_user_count_text }} in total</p>

        <pagination
          v-if="isNextPage(users.total)"
          v-model="page"
          :page-count="getPageCount(users.totalPages, users.total)"
          :page-range="5"
          :click-handler="clickPagerCallback"
        ></pagination>
      </div>
    </div>

    <no-data v-else title="No user available" />

    <download-csv
      ref="downloadCsv"
      style="display: none"
      :data="exportParams.data"
      :labels="exportParams.labels"
      :fields="exportParams.labels"
      name="user_wallet.csv"
    >
    </download-csv>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import pagination from '@/mixins/pagination'
import dateFormat from '@/mixins/date-range-format'
import unixTime from '@/mixins/unix-time'
import handleAssets from '@/mixins/handle-assets'
import momentltsFormat from '@/mixins/momentlts-format'
import toggleLoading from '@/mixins/toggle-loading'
import copyClipboard from '@/mixins/copy-clipboard'
import { PAGER_OPTIONS, ACCOUNT_STATUS_OPTIONS, USERS_CSV_FIELDS, USERS_CSV_LABELS } from '@/utils/data-sets'
const NoData = () => import('@/components/NoData')
const Pagination = () => import('@/components/Pagination')
const SelectionBox = () => import('@/components/SelectionBox')
const SearchBox = () => import('@/components/SearchBox')
const CopyClipboard = () => import('@/components/CopyClipboard')

export default {
  data() {
    return {
      params: {
        verified: 0,
        perPage: 20,
        sortBy: 'updatedAt'
      },
      searchText: '',
      publicKey: '',
      page: 1,
      pagerOptions: PAGER_OPTIONS,
      accountStatusOptions: ACCOUNT_STATUS_OPTIONS,
      exportParams: {
        labels: USERS_CSV_LABELS,
        fields: USERS_CSV_FIELDS,
        data: []
      },
      dateContext: {
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date()
      },
      isDateRangeOpen: false
    }
  },

  mixins: [pagination, dateFormat, unixTime, handleAssets, momentltsFormat, toggleLoading, copyClipboard],

  components: {
    NoData,
    Pagination,
    SelectionBox,
    SearchBox,
    CopyClipboard,
    DateRangePicker
  },

  computed: {
    ...mapState('user', ['users', 'assets'])
  },

  methods: {
    ...mapActions('user', ['getUsers', 'exportUsers']),

    onGetUsers() {
      this.toggleLoading()
      this.page = 1
      this.getUsers(this.getUsersRequestParams()).finally(() => {
        this.toggleLoading()
      })
    },

    clickPagerCallback() {
      this.toggleLoading()
      this.getUsers(this.getUsersRequestParams()).finally(() => {
        this.toggleLoading()
      })
    },

    getUsersRequestParams() {
      const { fromDate, toDate } = this.getDateRangeByUnixTime(this.dateContext)

      return {
        page: this.page,
        verified: this.params.verified,
        limit: this.params.perPage,
        sortBy: this.params.sortBy,
        email: this.searchText,
        startDate: fromDate,
        endTime: toDate,
        publicKey: this.searchText
      }
    },

    onExportUsersList() {
      this.toggleLoading()
      this.exportUsers()
        .then(response => {
          this.exportParams.data = response.data.result.data
        })
        .finally(() => {
          this.toggleLoading()
        })
    }
  },

  created() {
    this.getUsers(this.getUsersRequestParams()).finally(() => {
      this.toggleLoading()
    })
  },

  watch: {
    exportParams: {
      handler() {
        setTimeout(() => {
          this.$refs.downloadCsv.generate()
        }, 0)
      },

      deep: true
    }
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'User') {
      this.storeAssets('user')
      next()
    } else {
      this.clearAssets('user')
      next()
    }
  }
}
</script>
